import React from 'react';
import {
  ConditionsCollapsible,
  ContinuedTextListModule,
  FeaturedArticles,
  Hero,
  Module,
  StatisticStructuredBox,
  Structured
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';
import { Layout } from '../../redesign';

import heroContent from '../../redesign/content/menopause/hero.json';
import singleStatStructuredContent from '../../redesign/content/menopause/single-stat-structure.json';
import symptomsListContent from '../../redesign/content/menopause/symptoms-list.json';
import structuredContent from '../../redesign/content/menopause/structured-content';
import blogPostContent from '../../redesign/content/menopause/blog-post.json';
import p2 from '../../redesign/content/menopause/p2.json';
import { default as SEO } from '../../components/SEO';

export default function Menopause() {
  return (
    <Layout footerContentKey="findSupport">
      <SEO
        pageTitle="Natural Remedies for Menopause | Holistic Menopause Treatment | Parsley Health"
        pageDescription="Yes, there are better approaches for menopause. Discover natural remedies at Parsley Health, where holistic menopause treatments bring relief."
      />
      <Module>
        <Hero {...heroContent} />
      </Module>
      <Module marginTop="lg">
        <StatisticStructuredBox {...singleStatStructuredContent} />
      </Module>
      <Module>
        <ContinuedTextListModule {...symptomsListContent} />
      </Module>
      <Module>
        <ConditionsCollapsible {...p2} />
      </Module>
      <Module>
        <Structured {...structuredContent} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          ctaTrackingLabel="Meet Our Clinicians button on the Menopause page - Stress-free online holistic care module"
          eyebrow="Manage symptoms and feel your best"
        />
      </Module>
      <Module>
        <FeaturedArticles {...blogPostContent} />
      </Module>
    </Layout>
  );
}
