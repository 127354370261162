import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'Our clinical experts help you feel your best at any stage of life.',
  items: [
    {
      id: 'we-meet-you-where-youre-at',
      heading: "We meet you where you're at.",
      description:
        'Our clinicians take the time to get to know you, in person or virtually, with an in-depth assessment to understand the connection between your health history, your lifestyle, and your unique symptoms.',
      illustration: <IllustrativeIcon color="sage" name="personal" />
    },
    {
      id: 'advanced-diagnostics',
      heading: 'Advanced diagnostic testing.',
      description:
        'We run tests to check the status of your overall hormonal health and confirm if you’re perimenopausal or menopausal. If your doctor suspects you have any underlying conditions, we run tests to get to the root of your symptoms.',
      illustration: <IllustrativeIcon color="sage" name="testing" />
    },
    {
      id: 'your-partner-in-reconnecting',
      heading: 'Your partner in reconnecting with your body.',
      description:
        'Learn how to manage symptoms like hot flashes, discover supplements and herbs that balance hormones, and optimize your lifestyle for better menopausal health.',
      illustration: <IllustrativeIcon color="sage" name="teamCollab" />
    }
  ]
};

export default Data;
